import {
  Button,
  Container,
  Fab,
  Fade,
  Grid,
  Paper,
  Slide,
  TextField,
  useScrollTrigger,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/user-context";

import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import "./style.scss";
import { Link } from "react-router-dom";
import { DEvent, DEventSignup, DInquiry } from "../../model";
import { API } from "aws-amplify";
import { BSGAPI } from "../../api/bsg-api";
import { margin } from "@mui/system";

export default function EventSignupForm(props: any) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  const { t, i18n } = useTranslation("content");
  const context = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();

  const eventId = props.eventId as string;

  let event = (location.state as any).event as DEvent;

  const [uploadError, setUploadError] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");

  const [inquiry, setInquiry] = useState<DEventSignup>({
    firstName: "",
    lastName: "",
    eventId: eventId,
    phone: "",
    email: "",
    linkedin: "",
    message: "",
    google_calendar_id: event.google_calendar_id,
    google_event_id: event.google_event_id,
  });

  const [inquiryResponse, setInquiryResponse] = useState<any>({
    success: false,
    message: "",
  });

  function validate() {
    return (
      uploadError ||
      inquiry.firstName!.length < 1 ||
      inquiry.lastName!.length < 1 ||
      inquiry.email!.length < 2 ||
      inquiry.phone!.length < 7 ||
      inquiry.linkedin!.length < 26
    );
  }

  function callAPI() {
    BSGAPI.submitEventSignup(inquiry).then((response2: any) => {
      if (response2.status == "ok") {
        setInquiryResponse({
          success: true,
          message: "Your sign-up has been submitted!",
        });
      } else {
        setInquiryResponse({
          success: false,
          message:
            "Sorry. Your sign-up is not successfully submitted. Please try again later. ",
        });
      }
    });

    //setInquiryResponse(data);
    //setInquiry({});
  }

  useEffect(() => {
    event = (location.state as any).event as DEvent;
  }, [location]);

  return (
    <Container maxWidth="md">
      <div className="form">
        <h1>BSG Event Sign Up</h1>

        <Grid
          container
          item
          xs={12}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {eventId ? (
            <h3>
              You are signing up for this event:{" "}
              <Button
                variant="outlined"
                color="warning"
                onClick={() => {
                  history.push(`/events/${eventId}`);
                }}
              >
                {event.id}
              </Button>
              <h4 className="primary-dark">
                <i></i>
              </h4>
            </h3>
          ) : (
            <h3>You are signing up for BSG event.</h3>
          )}
        </Grid>
        {!inquiryResponse.success ? (
          <Grid
            container
            columnSpacing={1}
            rowSpacing={1}
            alignContent="stretch"
          >
            <Grid container item xs={12} columnSpacing={1} rowSpacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  color="secondary"
                  id="first-name"
                  label="First Name"
                  value={inquiry.firstName}
                  onChange={(e) => {
                    setInquiry({ ...inquiry, firstName: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  color="secondary"
                  id="last-name"
                  label="Last Name"
                  value={inquiry.lastName}
                  onChange={(e) => {
                    setInquiry({ ...inquiry, lastName: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} columnSpacing={1} rowSpacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  color="secondary"
                  id="email"
                  label="Your E-mail"
                  value={inquiry.email}
                  onChange={(e) => {
                    setInquiry({ ...inquiry, email: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  color="secondary"
                  id="phone"
                  label="Your Phone #"
                  value={inquiry.phone}
                  onChange={(e) => {
                    setInquiry({ ...inquiry, phone: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  color="secondary"
                  id="linkedin"
                  label="Your LinkedIn"
                  value={inquiry.linkedin}
                  onChange={(e) => {
                    setInquiry({ ...inquiry, linkedin: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <TextField
                variant="outlined"
                color="secondary"
                id="note"
                label="Anything you want us to know - Please keep it brief"
                multiline
                maxRows={10}
                rows={3}
                value={inquiry.message}
                onChange={(e) => {
                  setInquiry({ ...inquiry, message: e.target.value });
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              *{" "}
              <i>
                Your information will only be collected for BSG event
                communication purpose.
              </i>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="warning"
                onClick={callAPI}
                disabled={validate()}
              >
                Sign Up
              </Button>
              <h3 className="warning-dark">{inquiryResponse.message}</h3>
            </Grid>

            <Grid
              container
              item
              xs={12}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <h4 className="primary-dark"></h4>
              <h4 className="primary-dark"></h4>
              <h4 className="primary-dark"></h4>
            </Grid>
          </Grid>
        ) : (
          <div>
            <h2>Thank you!</h2>
            <h3>We will send you event details in email. </h3>
            <h3>You are all set and you may close this browser window now. </h3>
          </div>
        )}
      </div>
    </Container>
  );
}
