import { DEventSignup, DJobApplication } from "../model";

export class BSGAPI {
  static WEB_CONTENT_ROOT =
    "https://my-bsg-json.s3.amazonaws.com/content/bsg-web"; // reserved, NOt being used

  static MAX_UPLOAD_FILE_SIZE = 10 * 1000 * 1000; // bytes

  constructor() {}

  static config() {
    //console.log("This is config of BSG API class");
  }

  // get my profile (s3)
  static uploadFile(fileData: any, fileType: string) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          //"Cache-Control": "No-Cache",
          //"Content-Type": "application/json",
          "Content-Type": fileType,
          Authorization: ``,
        },
        //body: JSON.stringify({ user: user, params: {} }),
        body: fileData,
      };
      fetch("/api/my/upload-file", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          console.log("Error: " + err);
          reject(err);
        });
    });
  }

  // get my profile (s3)
  static submitJobApplication(application: DJobApplication) {
    return new Promise<any>((resolve, reject) => {
      //console.log("array Buffer: " + application.resume.arrayBuffer);
      //application.resume = Buffer.from(application.resume).toString("base64");
      const options = {
        method: "POST",
        headers: {
          //"Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: ``,
        },
        //body: JSON.stringify({ user: user, params: {} }),
        body: JSON.stringify({ data: application }),
      };
      fetch("/api/pub/submit-job-application", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          console.log("Error: " + err);
          reject(err);
        });
    });
  }

  // submit event sign up request
  static submitEventSignup(application: DEventSignup) {
    return new Promise<any>((resolve, reject) => {
      //console.log("array Buffer: " + application.resume.arrayBuffer);
      //application.resume = Buffer.from(application.resume).toString("base64");
      const options = {
        method: "POST",
        headers: {
          //"Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: ``,
        },
        //body: JSON.stringify({ user: user, params: {} }),
        body: JSON.stringify({ data: application }),
      };
      fetch("/api/pub/submit-event-signup", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          console.log("Error: " + err);
          reject(err);
        });
    });
  }
}
